<template>
	<b-modal
		ref="modal-status-changed"
		hide-footer
		@hidden="CLEAN_SPECIMENS(), $emit('hidden')"
		body-class="p-0 m-0"
		:title="titleModalStatus"
		no-close-on-backdrop
		size="xl"
	>
		<div v-if="[1, 2].includes(typeModalStatus)" class="p-4">
			<ValidationObserver tag="div" ref="form">
				<MainStates :in_galpon="null" isSpecimen :is_header="false"></MainStates>
			</ValidationObserver>
			<div class="d-flex align-items-center justify-content-end">
				<button class="btn btn-primary mt-1" @click="changeStatusSpecimens">Actualizar estados</button>
			</div>
		</div>

		<SpecimenStatusTracking
			v-if="[1, 3].includes(typeModalStatus)"
			:isEditingAdmin="isEditingAdmin"
			:specimenObj="specimenObj"
			:statusList="statusList"
			@editStatusAdmin="editStatusAdmin"
			@deleteTracking="deleteTracking"
		/>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import SpecimenService from "@/services/specimens.service"
import { getDiseases, saveDiseaseEspecimenTracking } from "@/views/brain/specimens/views/diseases/diseases.service.js"
// import { getClients, saveOuputSpecimenClient } from "@/views/brain/clients/clients.service.js";
import { getClients, saveOuputSpecimenClient } from "@/views/brain/others/views/clients/clients.service.js"
import { getUsersByRole } from "../../header-bookmarks/service.header-book.js"
import vSelect from "vue-select"
import SpecimenStatusChangedBody from "./SpecimenStatusChangedBody.vue"
import SpecimenStatusTracking from "./SpecimenStatusTracking.vue"
import moment from "moment"
import reasonsService from "@/services/reasons.service"
import MainStates from "@/views/amg/specimens/specimens-changes-status-massively/MainStatus.vue"
import { mapActions, mapMutations } from "vuex"
import { bus } from "@/main"

export default {
	props: {
		specimenObj: Object,
		arrayStatus: {
			type: Array,
			default: () => [],
		},
		typeModalStatus: {
			type: Number,
			default: 1,
			/* 1 = full */
			/* 2 = just edit */
			/* 3 = just tracking */
		},
	},
	components: {
		vSelect,
		SpecimenStatusChangedBody,
		SpecimenStatusTracking,
		MainStates,
	},
	async mounted() {
		this.isPreloading()
		this.disabledStatus(this.specimenObj.status)
		await Promise.all([
			this.getSpecimenWeightTrackings(),
			this.getUsers(),
			// this.getReasonsByModule(),
			// this.getDiseasesShow(),
			this.getClientsShow(),
		])

		this.toggleModal("modal-status-changed")
		this.isPreloading(false)
		if (this.typeModalStatus === 2) this.isEditingAdmin = true
		this.PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS({
			alias: this.specimenObj.alias,
			category_description: null,
			category_id: this.specimenObj.category_id,
			check: false,
			client_id: null,
			customFormData: [],
			date_status: [],
			description: null,
			disease_id: null,
			dob: null,
			evidences: [],
			inside: null,
			new_status_id: null,
			observations: null,
			others_description: null,
			photo: this.specimenObj.photo,
			plate: this.specimenObj.plate,
			pounds_weight: null,
			price: null,
			reason_death_id: null,
			responsable_id: null,
			specimen_id: this.specimenObj.id,
			status_description: this.specimenObj.s_description,
			status_id: this.specimenObj.status,
			thumb: this.specimenObj.thumb,
			type_ouput: null,
			value: null,
			weight: null,
			check: false,
			in_galpon: this.specimenObj.in_galpon,
		})
	},
	data() {
		return {
			statusList: [],
			arrayUsers: [],
			arrayReasons: [],
			arrayDiseases: [],
			arrayClients: [],
			arrayDeads: [
				{
					type: 3,
					id: null,
					observation: null,
					circumstances: null,
					evidences: [],
					customFormData: [],
					breeder_id: null, // criador
					others_description: null,
				},
			],
			specimen: {
				...this.specimenObj,
				date_status: moment().format("YYYY-MM-DD"),
				status: null,
				description: null,
				reason: null,
				disease: null,
				others_description: null,
				client_id: null,
				new_status_id: null,
				evidences: [],
			},

			isEditingAdmin: false,
		}
	},
	computed: {
		titleModalStatus() {
			return this.typeModalStatus === 2
				? `Cambiar estado de ${this.specimen.alias} / ${this.specimen.plate} -- ${this.specimen.s_description} `
				: `Estados de ${this.specimen.alias || this.specimen.plate}`
		},
	},
	methods: {
		...mapMutations("statusSpecimens", ["PUSH_FILTER_ARRAY_AVAILABLE_SPECIMENS", "M_INIT", "CLEAN_SPECIMENS"]),
		...mapActions("statusSpecimens", [
			"getUsersByRole",
			"getReasons",
			"getClients",
			"getDiseases",
			"getAllStatusSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
			"A_SAVE_STATUS_MASSIVELY_CHECK",
		]),
		async getSpecimenWeightTrackings() {
			let { data } = await axios.get("/api/specimens/get-tracking-status-weight", {
				params: {
					specimen_id: this.specimenObj.id,
				},
			})

			this.statusList = data
		},
		async getUsers() {
			this.arrayUsers = await getUsersByRole(null)
		},
		onlyForKilos(event, tracking) {
			let keyCode = event.keyCode ? event.keyCode : event.which
			// only allow number and one dot
			if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || tracking.tempKgWeight.indexOf(".") != -1)) {
				// 46 is dot
				event.preventDefault()
			}
			// restrict to 2 decimal places
			if (
				tracking.tempKgWeight != null &&
				tracking.tempKgWeight.indexOf(".") > -1 &&
				tracking.tempKgWeight.split(".")[1].length > 2
			) {
				event.preventDefault()
			}
		},
		convertKilosToPounds(tracking) {
			tracking.tempPoundsWeight = (tracking.tempKgWeight * 2.20462).toFixed(3)
		},
		editItem(tracking) {
			tracking.isEditing = true
			tracking.tempKgWeight = tracking.kg_weight
			tracking.tempPoundsWeight = tracking.pounds_weight
		},
		closeEditing(tracking) {
			tracking.isEditing = false
		},
		// async saveStatusWeight() {
		// 	if (!(await this.$refs.form.validate())) return

		// 	const confirm = await this.showConfirmSwal({
		// 		title: `¿Está seguro de actualizar el estado?`,
		// 	})
		// 	if (!confirm.value) return

		// 	this.isPreloading()
		// 	try {
		// 		const { data } = await axios.post("/api/specimens/save-status-weight", {
		// 			specimen_id: this.specimen.id,
		// 			status_id: this.specimen.new_status_id,
		// 			user_id: this.specimen.user_id || null, // preparador
		// 			kg_weight: this.specimen.weight > 0 ? this.specimen.weight : 0,
		// 			pounds_weight: isNaN(this.specimen.weight) ? 0 : (this.specimen.weight * 2.20462).toFixed(3),
		// 			current_user: this.currentUser.user_id,
		// 			date_status: this.specimen.date_status,
		// 			description:
		// 				this.specimen.new_status_id === 3
		// 					? this.arrayDeads[0].others_description
		// 					: this.specimen.description,
		// 			others_description: this.specimen.others_description,
		// 		})

		// 		//Status "ENFERMO"
		// 		if (this.specimen.new_status_id === 6) {
		// 			await this.saveDiseasesEspecimen(data[0].tracking_id)
		// 		}

		// 		//Status "VENDIDO", "PRESTADO", 'REGALADO','DONADO'
		// 		if ([2, 4, 5, 11].includes(this.specimen.new_status_id)) {
		// 			await this.saveOuputSpecimen(data[0].tracking_id)
		// 		}

		// 		//When the new Status is "Muerto"
		// 		if (this.specimen.new_status_id === 3) {
		// 			await this.saveDeadEspecimen()
		// 		}
		// 		//Whent actual status is "Muerto"
		// 		if ([2, 3, 4, 5, 11].includes(this.specimenObj.status)) {
		// 			await this.restoreSpecimen(data[0].tracking_id)
		// 		}

		// 		this.specimenObj.status = this.specimen.new_status_id
		// 		this.$emit("update-status")
		// 		this.disabledStatus(this.specimen.new_status_id)
		// 		this.cleandFields(true)
		// 		this.$refs.form.reset()
		// 		if (this.typeModalStatus === 2) {
		// 			this.toggleModal("modal-status-changed")
		// 			this.$emit("hidden")
		// 			return
		// 		}

		// 		await this.getSpecimenWeightTrackings()
		// 	} catch (error) {
		// 		this.showErrorSwal(error)
		// 	} finally {
		// 		this.isPreloading(false)
		// 	}
		// },
		cleandFields(full) {
			if (full) {
				this.specimen.status = null
				this.specimen.new_status_id = null
			}
			this.specimen.reason = null
			this.specimen.user_id = null
			this.specimen.description = null
			this.specimen.others_description = null
			this.specimen.disease = null
			this.specimen.weight = null
			this.specimen.client_id = null
			this.specimen.price = null
			this.arrayDeads = [
				{
					type: 3,
					id: null,
					observation: null,
					circumstances: null,
					evidences: [],
					customFormData: [],
					breeder_id: null,
					others_description: null,
					tracking: false,
				},
			]
		},
		cancelEditSpecimen() {
			if (this.typeModalStatus !== 2) {
				this.isEditingAdmin = false
			} else {
				this.toggleModal("modal-status-changed")
				this.$emit("hidden")
			}
			this.cleandFields(true)
		},

		async getReasonsByModule() {
			try {
				const data = await reasonsService.getByModule()
				this.arrayReasons = data
			} catch (error) {
				console.error(error)
			}
		},
		disabledStatus(param) {
			this.arrayStatus.forEach((status) => {
				status.disabled = false
				if (status.id === param) {
					status.disabled = true
				}
			})
		},
		findStatusDescription(status_id) {
			return this.arrayStatus.find((status) => status.id === status_id).description
		},
		async restoreSpecimen(last_tracking_id) {
			//When is death
			try {
				await axios.post("/api/deads/restore-specimen", {
					deleted_by: this.currentUser.user_id,
					specimen_id: this.specimen.id,
					last_tracking_id,
					last_specimen_status: this.specimenObj.status,
				})
			} catch (error) {
				console.error(error)
			}
		},

		//Death Status
		getBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = (error) => reject(error)
			})
		},
		async setEvidencesBase64() {
			for (let i = 0; i < this.arrayDeads.length; i++) {
				for (let k = 0; k < this.arrayDeads[i].evidences.length; k++) {
					await this.getBase64(this.arrayDeads[i].evidences[k]).then((data) => {
						let obj = {
							base: data,
							name: this.arrayDeads[i].evidences[k].name,
							size: this.arrayDeads[i].evidences[k].size,
						}
						this.arrayDeads[i].customFormData.push(obj)
					})
				}
			}
		},
		async saveDeadEspecimen() {
			try {
				await this.setEvidencesBase64()
				this.arrayDeads[0].id_specimen = this.specimen.id
				this.arrayDeads[0].id = this.specimen.plate
				this.arrayDeads[0].observation = this.specimen.reason
				await axios.post("/api/deads/save", {
					header: JSON.stringify(this.arrayDeads),
					created_by: this.currentUser.user_id,
				})
			} catch (error) {
				console.error(error)
			}
		},

		async editStatusAdmin() {
			const status = this.findStatusDescription(this.specimenObj.status)
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro de actualizar el estado "${status}"?`,
				text: "",
			})
			if (!confirm.value) return
			this.isEditingAdmin = true
		},

		async deleteTracking(item) {
			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro de eliminar el registro?`,
			})
			if (!confirm.value) return
			this.isPreloading()
			try {
				await this.getSpecimenWeightTrackings()
			} catch (error) {
				this.showErrorSwal(error)
			} finally {
				this.isPreloading(false)
			}
		},

		//Diseases
		async getDiseasesShow() {
			try {
				const data = await getDiseases()
				this.arrayDiseases = data
				this.arrayDiseases.push({
					id: 1,
					name: "OTROS",
				})
			} catch (error) {
				console.error(error)
			}
		},
		async saveDiseasesEspecimen(tracking_id) {
			const params = {
				tracking_id,
				disease_id: this.specimen.disease,
				description: this.specimen.description,
				others_description: this.specimen.others_description,
				created_by: this.currentUser.user_id,
			}
			try {
				await saveDiseaseEspecimenTracking(params)
			} catch (error) {
				console.error(error)
			}
		},

		//Donados and Vendidos
		async getClientsShow() {
			try {
				const data = await getClients()
				this.arrayClients = data
			} catch (error) {
				console.error(error)
			}
		},
		async saveOuputSpecimen(tracking_id) {
			let cleanPrice
			if (this.specimen.new_status_id === 2) {
				cleanPrice = JSON.parse(JSON.stringify(this.specimen.price))
				cleanPrice = this.justNumbers(cleanPrice)
			}
			const params = {
				tracking_id,
				client_id: this.specimen.client_id,
				price: cleanPrice,
				type_ouput:
					this.specimen.new_status_id === 2
						? 1
						: this.specimen.new_status_id === 4
						? 2
						: this.specimen.new_status_id === 5
						? 3
						: 4,
				created_by: this.currentUser.user_id,
			}
			try {
				await saveOuputSpecimenClient(params)
			} catch (error) {
				console.error(error)
			}
		},
		async changeStatusSpecimens() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campor por llenar"
				)
				return
			}

			const confirm = await this.showConfirmSwal({
				title: `¿Está seguro de actualizar el estado?`,
			})
			if (!confirm.value) return
			this.isPreloading()
			try {
				await this.A_SAVE_STATUS_MASSIVELY()

				this.showToast(
					"success",
					"top-right",
					"Ejemplar",
					"SuccessIcon",
					"Se actualizó el estado de forma correcta."
				)

				this.CLEAN_SPECIMENS()
				bus.$emit("RefreshCounters")
				this.$emit("update-status")
				this.$emit("hidden")
			} catch (error) {
				this.showErrorSwal(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"specimen.new_status_id"() {
			this.cleandFields(false)
		},
	},
	mixins: [modalMixin],
}
</script>

<style lang="scss"></style>
