<template>
	<b-modal
		ref="modal-specimens-by-status"
		:title="'Ejemplares'"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="lg"
		hide-footer
	>
		<div>
			<b-row>
				<b-col md="6">
					<div class="d-flex">
						<span class="" style="font-weight: bold">Categoria</span>
						<p class="text-primary" style="font-weight: bold; text-transform: uppercase">
							: {{ info.category }}
						</p>
					</div>
				</b-col>
				<b-col md="6">
					<div class="d-flex">
						<span class="" style="font-weight: bold">Estado</span>
						<p class="text-primary" style="font-weight: bold; text-transform: uppercase">
							: {{ info.status }}
						</p>
					</div>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" xl="5" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
					/>
					<label class="mr-2">registros</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header">
					<b-th class="text-center">Ejemplar</b-th>
					<b-th class="text-center">Estado</b-th>
					<b-th class="text-center">Categoria</b-th>
				</b-thead>
				<b-tbody>
					<b-tr :key="index" v-if="specimens.length > 0" v-for="(specimen, index) in specimens">
						<b-td class="text-center">
							<SpecimenPlate :specimen="specimen" :show_name_cate="false" />
						</b-td>
						<b-td class="text-center">
							<div class="d-flex align-items-center justify-content-center gap-x-1">
								<feather-icon
									size="18"
									v-b-tooltip.hover.top="
										specimen.status.in_galpon === 1 ? 'Disponible' : 'No disponible'
									"
									:class="{
										'text-danger': specimen.status.in_galpon === 0,
										'text-success': specimen.status.in_galpon === 1,
									}"
									icon="CheckCircleIcon"
								></feather-icon>
								<span style="text-transform: uppercase">{{ specimen.status.name }}</span>
							</div>
							<feather-icon
								v-if="specimen.status.id != 3"
								icon="Edit2Icon"
								class="text-warning cursor-pointer ml-2"
								@click="openSpecimenStatusChanged(specimen)"
							/>
						</b-td>
						<b-td class="text-center">{{ specimen.category.name }}</b-td>
					</b-tr>
					<b-tr v-if="specimens.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<SpecimenStatusChangedModal
				v-if="specimenStatusController"
				@hidden="specimenStatusController = false"
				:specimen-obj="selectedSpecimen"
				:array-status="arrayStatus"
				@update-status="getSpecimens(), $emit('refresh')"
			></SpecimenStatusChangedModal>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import specimenStatusService from "@/services/specimenStatus.service"
import specimenService from "@/services/specimens.service"
import SpecimenStatusChangedModal from "@/views/amg/specimens/components/SpecimenStatusChangedModal.vue"

export default {
	components: { SpecimenPlate, SpecimenStatusChangedModal },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		statusId: Number,
		categoryId: Number,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			specimens: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 50,
				from: 0,
				to: 0,
			},
			search_input: "",
			selectedSpecimen: {},
			specimenStatusController: false,
			arrayStatus: [],
		}
	},
	async created() {
		await this.getSpecimens()
	},
	computed: {},
	methods: {
		async refresh() {
			await this.getSpecimens()
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getSpecimens() {
			this.isPreloading()
			try {
				const { data } = await specimenStatusService.getSpecimensByStatus({
					status_id: this.statusId,
					category_id: this.categoryId,
					search: this.search_input,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
				})
				this.specimens = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async openSpecimenStatusChanged(specimen) {
			if (this.arrayStatus.length == 0) {
				await this.getSpecimenStatusTrackings()
			}
			console.log("specimens: ", specimen)
			this.selectedSpecimen = {
				id: specimen.id,
				alias: specimen.alias,
				plate: specimen.plate,
				status: specimen.status.id,
				thumb: specimen.thumb,
				photo: specimen.image,
				in_galpon: specimen.status.in_galpon,
				s_description: specimen.status.name,
				category_id: specimen.category.id,
			}
			this.specimenStatusController = true
		},
		async getSpecimenStatusTrackings() {
			this.arrayStatus = await specimenService.getAllStatusSpecimens()
			this.arrayStatus = this.arrayStatus.filter((item) => item.id != 3)
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getSpecimens()
		},
		"pagination.perPage": async function () {
			await this.getSpecimens()
		},
	},
	async mounted() {
		this.toggleModal("modal-specimens-by-status")
	},
}
</script>

<style>
.label-filter {
	font-size: 0.8rem;
}
</style>
