<template>
	<b-overlay :show="isLoading">
		<b-card class="p-1" no-body>
			<b-row class="mb-2 d-flex justify-content-between">
				<b-col class="d-flex align-items-center" cols="12" md="6">
					<b-tabs v-model="indexTab">
						<b-tab :disabled="false">
							<template #title>Disponible</template>
						</b-tab>
						<b-tab :disabled="false">
							<template #title>No disponible</template>
						</b-tab>
					</b-tabs>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end" cols="12" md="6">
					<b-button @click="creationModal.show = true" variant="success">Crear Estado</b-button>
				</b-col>
			</b-row>

			<div>
				<StatusTable :indexTab="indexTab" :refreshNum="refreshNum" />
			</div>

			<CreateStatusModal
				v-if="creationModal.show"
				:indexTab="indexTab"
				:origin="'create'"
				@closing="creationModal.show = false"
				@refresh="refreshNum += 1"
			/>
		</b-card>
	</b-overlay>
</template>

<script>
import CreateStatusModal from "../components/CreateStatusModal.vue"
import StatusTable from "@/views/brain/specimens/views/StatusTable.vue"

export default {
	components: { CreateStatusModal, StatusTable },
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: { show: false, info: {} },
		indexTab: 0,
		refreshNum: 1,
	}),
	methods: {},
	created() {},
}
</script>
