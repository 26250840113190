<template>
	<b-modal
		ref="configure-reason-modal"
		centered
		:title="getTitle"
		v-model="isActive"
		no-close-on-backdrop
		@hidden="handleHidden(false)"
		size="md"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<div class="d-flex align-items-center justify-content-end">
				<b-button variant="primary" style="white-space: nowrap" @click="openModifyReason('create')">
					Agregar motivo
				</b-button>
			</div>

			<b-table-simple class="mt-1" sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>#</b-th>
						<b-th>Motivo</b-th>
						<b-th>Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(reason, index) in reasons" :key="index">
						<b-td class="text-center">
							<span>{{ index + 1 }}</span>
						</b-td>
						<b-td class="text-center">
							<span>{{ reason.reason }}</span>
						</b-td>
						<b-td class="text-center">
							<div class="d-flex justify-content-center">
								<b-button variant="flat-warning" @click="openModifyReason('edit', reason)">
									<feather-icon size="20" icon="EditIcon" />
								</b-button>
								<b-button variant="flat-danger" @click="deleteReason(reason)">
									<feather-icon size="20" icon="TrashIcon" />
								</b-button>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="reasons.length === 0">
						<b-td colspan="3" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>

		<b-modal v-if="showAdd" v-model="showAdd" :title="getModifyTitle" centered size="md" @hidden="closeModify">
			<b-form-input v-model="name" />
			<template #modal-footer>
				<b-button v-if="actionModal === 'create'" variant="success" @click="save">Guardar</b-button>
				<b-button v-else variant="success" @click="edit(reason)">Guardar</b-button>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import specimenStatusService from "@/services/specimenStatus.service"

export default {
	props: {
		show: Boolean,
		info: Object,
		indexTab: Number,
		statusId: Number,
	},
	mixins: [modalMixin],
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		name: null,
		galpon: 1,
		reasons: [],
		showAdd: false,
		actionModal: "",
		reasonInfo: {},
	}),
	computed: {
		getTitle() {
			return `Motivos del estado ${this.info.description.toUpperCase()}`
		},
		getModifyTitle() {
			return `${this.actionModal === "create" ? "Agregar nuevo motivo" : "Editar motivo"}`
		},
	},
	async mounted() {
		this.toggleModal("configure-reason-modal")
	},
	methods: {
		handleHidden(is_refresh) {
			this.isActive = false
			this.isLoading = false
			this.name = null
			this.galpon = 1
			this.$emit("closing", { is_refresh, info: this.info })
		},
		closeModify() {
			this.showAdd = false
			this.name = null
		},
		openModifyReason(action, reason) {
			if (action === "create") {
				this.name = null
			} else {
				this.name = reason.reason
			}
			this.actionModal = action
			this.showAdd = true
			this.reasonInfo = reason
		},
		async getReasons() {
			this.isPreloading()
			try {
				const { data } = await specimenStatusService.getReasons({ status_id: this.statusId })
				this.reasons = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async editReason(reason) {
			console.log(reason)
		},
		async deleteReason(reason) {
			const { isConfirmed } = await this.showConfirmSwal({ text: `Se eliminará el motivo ${reason.reason}.` })
			if (!isConfirmed) return
			this.isPreloading()

			try {
				const res = await specimenStatusService.deleteReason({ id: reason.id })
				this.getReasons()
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async save() {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()
			try {
				const res = await specimenStatusService.createReason({ status_id: this.statusId, reason: this.name })
				this.getReasons()
				this.$emit("refresh")
				this.closeModify()
				console.log(res)
			} catch (error) {
				const message = error.response?.data?.data?.error
				console.log(error)
				console.log(message)

				this.showToast("warning", "top-right", "Advertencia", "AlertTriangleIcon", `${message}`)
				return
			} finally {
				this.isPreloading(false)
			}
		},
		async edit() {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()
			try {
				const res = await specimenStatusService.editReason({
					id: this.reasonInfo.id,
					reason: this.name,
					status_id: this.statusId,
				})
				this.getReasons()
				this.closeModify()
			} catch (error) {
				const message = error.response?.data?.data?.error
				console.log(error)
				console.log(message)

				this.showToast("warning", "top-right", "Advertencia", "AlertTriangleIcon", `${message}`)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	async created() {
		await this.getReasons()
	},
}
</script>
