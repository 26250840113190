<template>
	<b-modal ref="create-status-modal" centered :title="getTitle" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-group :label="origin === 'create' ? 'Nombre' : 'Editar nombre'">
				<b-form-input v-model="name" />
			</b-form-group>
			<div v-if="origin === 'create'">
				<div class="my-1">
					<b-badge v-if="indexTab === 0" variant="success">Disponible</b-badge>
					<b-badge v-else variant="danger">No disponible</b-badge>
				</div>
				<b-form-group>
					<b-form-checkbox v-model="is_reason">Aplica motivos</b-form-checkbox>
					<div class="mt-1" style="display: flex; align-items: center; gap: 1rem">
						<b-form-checkbox v-model="is_days_without_topes">Dias sin topar</b-form-checkbox>
						<b-form-input
							class="border-primary rounded"
							type="number"
							v-model="days_without_topes"
							placeholder="Cantidad de dias..."
							:disabled="!is_days_without_topes"
							style="height: 2rem; width: 30%; font-size: 0.85rem"
						/>
					</div>
				</b-form-group>
				<b-form-group>
					<p>Categorias:</p>
					<div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 0.5rem">
						<b-form-checkbox
							v-for="category in categoryOpts"
							:key="category.id"
							v-model="category.is_check"
						>
							{{ category.description }}
						</b-form-checkbox>
					</div>
				</b-form-group>
			</div>
		</b-overlay>
		<template #modal-footer>
			<b-button v-if="origin === 'edit'" variant="success" @click="edit" :disabled="!!!name">Guardar</b-button>
			<b-button v-else variant="success" @click="create" :disabled="!!!name">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import specimenStatusService from "@/services/specimenStatus.service"
import { mapState } from "vuex"
import modalMixin from "@/mixins/modal"

export default {
	props: {
		show: Boolean,
		indexTab: Number,
		origin: String,
		info: Object,
	},
	mixins: [modalMixin],
	data: () => ({
		isActive: false,
		isLoading: false,
		is_reason: false,
		is_days_without_topes: false,
		days_without_topes: null,
		title: null,
		name: null,
		galpon: 1,
		categoriesList: [],
	}),
	computed: {
		getTitle() {
			return `${this.origin === "create" ? "Crear nuevo estado" : `Editar estado (${this.info.description})`}`
		},
		...mapState("createEditSpecimen", ["S_OPTIONS_MACHO", "S_OPTIONS_HEMBRA"]),
		categoryOpts() {
			return [...this.S_OPTIONS_MACHO, ...this.S_OPTIONS_HEMBRA].map((option) => ({
				...option,
				is_check: false,
			}))
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.name = null
			this.galpon = 1
			this.$emit("closing")
		},
		async create() {
			const { isConfirmed } = await this.showConfirmSwal({})
			if (!isConfirmed) return
			this.isPreloading()

			try {
				await specimenStatusService.createSpecimenStatus({
					name: this.name,
					type: this.indexTab === 0 ? 1 : 0,
					is_reason: this.is_reason ? 1 : 0,
					is_days_without_topes: this.is_days_without_topes ? 1 : 0,
					days_without_topes: this.days_without_topes ? this.days_without_topes : null,
					categories: this.categoryOpts.map((cat) => ({ id: cat.id, is_check: cat.is_check ? 1 : 0 })),
				})
				this.handleHidden()
				this.$emit("refresh")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async edit() {
			const { isConfirmed } = await this.showConfirmSwal({})
			if (!isConfirmed) return
			this.isPreloading()

			try {
				await specimenStatusService.editSpecimenStatus({
					id: this.info.id,
					name: this.name,
				})
				this.handleHidden()
				this.$emit("refresh")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		setFields() {
			this.name = this.info.description
			this.galpon = this.info.in_galpon == 1 ? 1 : 2
		},
	},
	async mounted() {
		this.toggleModal("create-status-modal")
	},
}
</script>
