<template>
	<b-modal
		ref="configure-days-modal"
		centered
		:title="getTitle"
		v-model="isActive"
		no-close-on-backdrop
		@hidden="handleHidden(false)"
		size="md"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<div class="d-flex">
				<div class="p-1 container days-counter" no-body>
					<b-row>
						<b-col class="mb-1" cols="12" md="11" style="margin-left: auto; margin-right: auto">
							<b-form-group label="Cantidad mínima de dias">
								<vue-number-input center vertical controls v-model="days" :min="21" />
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col class="text-right">
							<b-button size="lg" variant="success" :disabled="isLoading || !hasChanges" @click="save">
								Guardar
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</b-overlay>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import { mapActions } from "vuex"
import specimenStatusService from "@/services/specimenStatus.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	mixins: [modalMixin],
	data: () => ({
		isActive: false,
		isLoading: false,
		days: 21,
		clearConfig: { days: 0 },
	}),
	computed: {
		getTitle() {
			return `Dias sin topar del estado (${this.info.description})`
		},
		hasChanges() {
			return this.days != this.clearConfig.days
		},
	},
	async mounted() {
		this.toggleModal("configure-days-modal")
	},
	methods: {
		...mapActions({
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async save() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se actualizará la configuracion para dias sin topar del estado (${this.info.description}).`,
			})
			if (!isConfirmed) return
			this.isPreloading()

			try {
				const res = await specimenStatusService.setDays({
					status_id: this.info.id,
					days_without_topes: this.days,
				})
				this.$emit("refresh")
				await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()
				this.$emit("closing")
				this.showToast("success", "top-right", "Cerebro", "CheckIcon", "Configuracion guardada exitosamente")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	created() {
		this.days = this.info.days_without_topes
		this.clearConfig.days = this.info.days_without_topes
	},
}
</script>

<style scoped>
.container {
	margin: auto auto;
	width: 100%;
}

/* @media screen and (min-width: 1024px) {
	.container {
		width: 30%;
	}
}

@media screen and (min-width: 1024px) {
	.days-counter {
		width: 25%;
	}
} */
</style>
