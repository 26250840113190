<template>
	<div class="p-2">
		<!-- <h3 class="px-2 pb-1 m-0 mt-0 pt-0">
			Tracking del Ejemplar
			<span v-if="isAdmin && !isEditingAdmin && [2, 3, 4, 11].includes(specimenObj.status)"></span>
				<feather-icon
					icon="EditIcon"
					id="editDead"
					class="cursor-pointer mr-1 text-info"
					size="20"
					v-b-tooltip.hover.top="'Cambiar estado'"
					@click="$emit('editStatusAdmin')"
				/>
			</span>
		</h3> -->
		<b-table
			:items="statusList"
			:fields="visibleFields"
			:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
			sticky-header="75vh"
			responsive
			class="card"
		>
			<template #cell(kg_weight)="data">
				<p class="mb-0" v-if="data.item.kg_weight">
					<span class="font-weight-bolder">{{ data.item.kg_weight }}</span>
					Kg
				</p>
				<p class="mb-0" v-if="data.item.pounds_weight">
					<span class="font-weight-bolder">{{ data.item.pounds_weight }}</span>
					Lb
				</p>
			</template>

			<template #cell(description)="data">
				<!-- Special Status (VENDIDO,REGALADO,DONADO, MUERTO,ENFERMO) -->
				<div v-if="[2, 4, 11, 3, 6].includes(data.item.status)">
					<div v-if="[2, 4, 11].includes(data.item.status)">
						<p class="mb-0" v-if="data.item.price">S/. {{ data.item.price }}</p>
						<p class="mb-0 font-weight-bolder" v-if="data.item.client_name">
							{{ data.item.client_name }}
						</p>
						<span>{{ data.item.description }}</span>
					</div>
					<div v-if="data.item.status === 3">
						<p>{{ data.item.reason_death }}</p>
						<span v-if="data.item.reason_death === 17">{{ data.item.others_description }}</span>
						<span v-else>{{ data.item.description }}</span>
					</div>
					<div v-if="data.item.status === 6">
						<p>{{ data.item.disease }}</p>
						<span v-if="data.item.disease_id === 1">{{ data.item.disease_others_description }}</span>
						<span v-else>{{ data.item.disease_description }}</span>
					</div>
				</div>

				<!-- Normal Status -->
				<div v-if="![2, 4, 11, 3, 6].includes(data.item.status)">
					<p class="mb-0">{{ data.item.description }}</p>
				</div>
			</template>

			<template #cell(date_status)="data">
				{{ data.item.date_status | myGlobalDayWhour }}
			</template>

			<template #cell(created_at)="data">
				{{ data.item.name }}
				<br />
				{{ data.item.created_at | myGlobalDayWhour }}
			</template>

			<template #cell(status_name)="data">
				<div class="d-flex align-items-center justify-content-center gap-x-1">
					<feather-icon
						size="18"
						v-b-tooltip.hover.top="data.item.in_galpon === 1 ? 'Disponible' : 'No disponible'"
						:class="{
							'text-danger': data.item.in_galpon === 0,
							'text-success': data.item.in_galpon === 1,
						}"
						icon="CheckCircleIcon"
					></feather-icon>

					<span style="font-weight: lighter">({{ data.item.status_name }})</span>
				</div>
			</template>

			<template #cell(reason_general)="data">
				<div class="d-flex align-items-center justify-content-center gap-x-1">
					<span style="">{{ data.item.reason_general ?data.item.reason_general:'---'  }}</span>
				</div>
			</template>

			<!-- <template #cell(actions)="data">
        <template v-if="isAdmin">
          <feather-icon
            icon="Trash2Icon"
            class="text-danger cursor-pointer"
            v-b-tooltip.hover.top="'Borrar Estado'"
            @click="$emit('deleteTracking', data.item)"
          />
        </template>
      </template> -->
		</b-table>
	</div>
</template>

<script>
export default {
	name: "SpecimenStatusTracking",
	props: {
		statusList: {
			type: Array,
			default: [],
		},
		isEditingAdmin: {
			type: Boolean,
			default: false,
		},
		specimenObj: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			columns: [
				{
					key: "status_name", // status
					label: "Estado",
					class: "text-center font-weight-bolder",
					visible: true,
				},
				{
					key: "reason_general", // status
					label: "Motivo",
					class: "text-center font-weight-bolder",
					visible: true,
				},
				{
					key: "responsable",
					label: "Responsable",
					class: "text-center",
					visible: true,
				},
				{
					key: "kg_weight",
					label: "Peso",
					class: "text-center",
					visible: true,
				},
				{
					key: "description",
					label: "Descripción",
					class: "text-center",
					visible: true,
				},
				{
					key: "date_status",
					label: "Fecha",
					class: "text-center",
					visible: true,
				},
				{
					key: "created_at",
					label: "Ingresado por",
					class: "text-center",
					visible: true,
				},
				// {
				//   key: "actions",
				//   label: "Acciones",
				//   class: "text-center",
				//   visible: true,
				// },
			],
		}
	},
	computed: {
		visibleFields() {
			return this.columns.filter((column) => column.visible)
		},
	},
}
</script>

<style lang="scss">
.card {
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
	// background: red;
}
</style>
